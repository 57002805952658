:root {
  --primary-color: #ed9a4a;
  --secondary-color: #bc252c;
  --tertiary-color: #e6af2e;
  --bg-color: #f4e1b7;
  --bg-dark-color: #302d2a;
  --dark-color: #1d1c1b;
  --menu-height: 80px;
}

*,
*::before,
*::after,
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  color: var(--dark-color);
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: var(--tertiary-color);
}

a,
h3 {
  color: var(--primary-color);
}

ul {
  list-style: none;
  padding: 0;
}

ul > li {
  padding: 0;
}

header,
main,
h1,
h2 {
  font-family: 'Staatliches', sans-serif;
}

h2 {
  font-size: 2em;
  letter-spacing: 0.25rem;
}

h3 {
  font-family: 'Staatliches', sans-serif;
  font-size: 1.3em;
  letter-spacing: 0.15rem;
}
